<template>
  <div id="app">
    <embed :src="getImage('sprite1.svg')" />
    <embed :src="getImage('sprite2.svg')" />
    <embed :src="getImage('sprite3.svg')" />
    <embed :src="getImage('sprite4.svg')" />
    <embed :src="getImage('sprite5.svg')" />
    <embed :src="getImage('sprite6.svg')" />
    <mh-header
    :anchors="[
      { label: 'Nos prestations', ref: 'presta' },
      { label: 'Nos offres', ref: 'offers' },
      { label: 'Contact', ref: 'contact' }
    ]"
    />
    
    <mh-title
    title="Chassez en continu vos profils pénuriques !"
    subtitle="Manque de temps ou de ressources pour adresser vos challenges RH ? Nous nous positionnons comme votre partenaire privilégié et durable en matière de recrutement, complétant ainsi vos canaux de recrutement habituels."
    />
    
    <mh-content
    ref="presta"
    title="A votre écoute nous analysons vos besoins"
    description="Nous prenons le temps d'étudier votre secteur d'activité, votre culture d'entreprise et vos objectifs à long terme, afin de mieux comprendre vos exigences et de trouver les candidats les plus adaptés. Un accompagnement sur mesure qui permet d’atteindre plus de candidats à fort potentiel tout en mettant en avant votre société."
    imageFile="img1.svg"
    fade
    />
    
    <mh-content
    title="Ciblage et création du vivier des candidats"
    description="Nos chasseurs de tête analysent et identifient, des profils précis répondant à une liste de critères de sélection pour un poste professionnel donné. Nous approchons les bons profils à vous rencontrer rapidement. Vous accéderez à des candidats qui requièrent un sourcing et un processus de recrutement différent."
    imageFile="img2.svg"
    reverse
    />

    <mh-content
      title="Sélection des candidats"
      description="Nous effectuons un controle sur les compétences et savoir de chaque candidats via une évaluation précise des qualifications et de l'adéquation culturelle, ce qui nous permet de vous présenter des candidats de qualité qui répondent à vos attentes."
    >
      <mh-profiles :profiles="profiles" />
    </mh-content>

    <mh-cards
      ref="offers"
      :cards="formulas"
      title="Nos offres"
      description="Nous proposons 4 types d'offres d’accompagnement, ajustable sur 3 à 8 mois"
    />

    <mh-contact ref="contact" />

  </div>
</template>

<script>
import MhHeader from './components/MhHeader.vue';
import MhCards from './components/MhCards.vue';
import MhContent from './components/MhContent.vue';
import MhProfiles from './components/MhProfiles.vue';
import MhTitle from './components/MhTitle.vue';
import MhContact from './components/MhContact.vue';

export default {
  name: "App",
  components: { MhTitle, MhContent, MhProfiles, MhCards, MhContact, MhHeader },
  provide () {
    return {
      scrollTo: this.scrollTo,
      getImage: this.getImage
    }
  },
  data () {
    return {
      profiles: [
        { name: 'Amaury Grinda', job:'Opérateur de pesée', image: 'Operateur_pesee.png' },
        { name: 'Maud Darche', job:'Tech lead', image: 'Tech_lead.jpeg' },
        { name: 'Guy Carpentier', job:'Conducteur de ligne', image: 'Conducteur_ligne.jpeg' },
        { name: 'Sigolène Chaney', job:'Project leader', image: 'Project_leader.jpeg' },
        { name: 'Silvain Vernier', job:'Opérateur de production', image: 'Operateur_production.jpeg' },
        { name: 'Gautier Boulle', job:'Merchandiser', image: 'Merchandiser.jpeg' },
        { name: 'Lucas Magnier', job:'Développeur full stack', image: 'Developpeur_fullstack.jpeg' },
        { name: 'Giselle Gainsbourg', job:'Business développeuse', image: 'Business_developpeuse.jpeg' },
        { name: 'Quentin Manoury', job:'Technicien frigoriste', image: 'Technicien_frigoriste.jpg' },
        { name: 'Marie Chapuis', job:'Contrôleuse de gestion', image: 'Controleuse_gestion.jpeg' },
        { name: 'Justine Carré', job:'Dessinatrice projeteur', image: 'Dessinatrice_projeteur.jpeg' },
        { name: 'Océane Peltier', job:'Acheteur', image: 'Acheteur.jpg' },
        { name: 'Valentin Chappuis', job:'Métreur', image: 'Metreur.jpeg' },
        { name: 'Laurence Verninac', job:'Supply planner', image: 'Supply_planner.jpg' },
        { name: 'Philippe Dupuis', job:'Data analyst', image: 'Data_analyst.jpg' },
      ],
      formulas: [
        {
          id: 1,
          name: 'Essentiel',
          nSearch: 1,
          opt1: { duration: 8, price: 2700 },
          opt2: { duration: 4, price: 3000 }
        },
        {
          id: 2,
          name: 'Essentiel plus',
          nSearch: 2,
          opt1: { duration: 8, price: 5000 },
          opt2: { duration: 4, price: 5250 }
        },
        {
          id: 3,
          name: 'Essentiel max',
          nSearch: 3,
          opt1: { duration: 8, price: 7500 },
          opt2: { duration: 4, price: 7750 }
        },
        {
          id: 4,
          name: 'Express',
          nSearch: 1,
          opt1: { duration: 3, price: 4000 }
        },
      ]
    }
  },
  methods: {
    scrollTo(refName) { this.$refs[refName].$el.scrollIntoView({ behavior: 'smooth' }) },
    getImage (image) {
      return image && require(`@/assets/${image}`)
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: #050B20;
  overflow-x: hidden;
  * {
    box-sizing: border-box;
    font-size: max(
      min(
        calc(
          var(--min-size, 15) * 0.1rem +
          (var(--max-size, 18) - var(--min-size, 15)) *
          (100vw - 320px) / 1600
        ),
        calc(var(--max-size, 18) * 0.1rem)
      ),
      calc(var(--min-size, 15) * 0.1rem)
    );
  }
}

#app {
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  text-align: center;
  padding: 0 20px;
  min-height: 100%;
  font-size: 1em;
  position: relative;
  > section {
    max-width: var(--max-width, 124rem);
    margin: 0 auto;
    --spacing: max(min(calc(5rem + 50 * (100vw - 320px) / 1600), 8rem), 5rem);
    .description {
      margin: 0;
      font-weight: 400;
      color: #878EA0;
    }
  }
  > embed {
    z-index: -1;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    &:nth-of-type(1) { left: 0; top: 0 }
    &:nth-of-type(2) { left: 100%; top: 25% }
    &:nth-of-type(3) { left: 0; top: 65% }
    &:nth-of-type(4) { left: 70%; top: 75% }
    &:nth-of-type(5) { left: 80%; top: 80% }
    &:nth-of-type(6) { left: 100%; top: 100%; transform: translate(-100%, -75%) }
    // background-position: 0 0, 0 65%, 75% 75%, 80% 80%
  }
}

</style>
