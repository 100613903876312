<template>
    <span
        @click="$emit('change', !value)"
        class="mh-switch"
        :class="value ? 'on' : 'off'"
    />
</template>

<script>
export default {
    name: 'MhSwitch',
    props: {
        value: { type: Boolean }
    },
    model: { prop: 'value', event: 'change' }
}
</script>

<style lang="scss">
.mh-switch {
    cursor: pointer;
    display: block;
    width: min(calc(5.5rem + 20 * (100vw - 320px) / 1600), 7.5rem);
    height: min(calc(3rem + 10 * (100vw - 320px) / 1600), 4rem);
    border-radius: 30px;
    background-color: #0A3FF5;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(50% - 2px);
        height: calc(100% - 4px);
        border-radius: 50%;
        background-color: #FFFFFF;
        transition: left 0.1s ease-out;
    }
    &.on::before {
        left: 50%;
    }
}
</style>