<template>
    <div class="mh-header">
        <img :src="getImage('logo.svg')">
        <a v-for="anc in anchors" :key="anc.ref" @click="scrollTo(anc.ref)">
            {{ anc.label }}
        </a>
    </div>
</template>

<script>
export default {
    name: 'MhHeader',
    inject: [ 'scrollTo', 'getImage' ],
    props: {
        anchors: { type: Array, required: true }
    }
}
</script>

<style lang="scss">
@media (max-width: 720px) {
    .mh-header > a {
        display: none
    }
}
.mh-header {
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    padding: 2rem 5rem;
    margin-top: 4.4rem;
    text-align: right;
    backdrop-filter: blur(3px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    > img { margin-right: auto }
    > a {
        margin-left: 3.4rem;
        font-size: 1.4rem;
        cursor: pointer;
        &:hover { text-decoration: underline }
    }
}
</style>