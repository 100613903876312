<template>
    <div class="mh-profiles">
        <div class="rowA" :style="`--nb: ${listA.length / 2}`">
            <div class="row">
                <span v-for="(p, index) in listA" :key="`A${index}`">
                    <img :src="getImage(p.image)">
                    <div class="name">{{ p.name }}</div>
                    <div class="job">{{ p.job }}</div>
                </span>
            </div>
        </div>
        <div class="rowB" :style="`--nb: ${listB.length / 2}`">
            <div class="row">
                <span v-for="(p, index) in listB" :key="`B${index}`">
                    <img :src="getImage(p.image)" :alt="p.job">
                    <div class="name">{{ p.name }}</div>
                    <div class="job">{{ p.job }}</div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MhProfiles',
    inject: [ 'getImage' ],
    props: {
        profiles: { type: Array, required: true }
    },
    computed: {
        lists () {
            const A = this.profiles.slice(0, parseInt(this.profiles.length / 2))
            const B = this.profiles.slice(parseInt(this.profiles.length / 2))
            return [A, B]
        },
        listA () { return this.lists[0].concat(this.lists[0]) },
        listB () { return this.lists[1].concat(this.lists[1]) }
    }
}
</script>

<style lang="scss">
.mh-profiles {
    margin-left: -20px;
    margin-right: -100%;
    .row {
        display: flex;
        flex-flow: row nowrap;
        gap: 12px;
        overflow: visible;
        > span {
            flex: 0 0 250px;
            height: 75px;
            border-radius: 8px;
            border: 1px solid #172469;
            display: grid;
            grid-template-areas:
            "img name"
            "img job";
            grid-template-columns: 75px 1fr;
            align-items: center;
            text-align: left;
            line-height: normal;
            > img {
                margin: auto;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                object-fit: cover;
                grid-area: img;
            }
            > .name {
                font-size: 16px;
                font-weight: 700;
                align-self: end;
            }
            > .job {
                font-size: 13px;
                font-weight: 400;
                align-self: start;
            }
        }
        &:last-child { margin-top: 12px }
    }
    .rowA span:nth-child(even), .rowB span:nth-child(odd) { border-color: #1A3DEA }
    .rowA, .rowB { overflow: hidden }
    .rowA > .row {
        @keyframes animA {
            from { transform: translatex(0) }
            to { transform: translatex(calc(-264px * var(--nb))) }
        }
        animation: animA calc(var(--nb) * 15s) linear infinite;
    }
    .rowB > .row {
        @keyframes animB {
            from { transform: translatex(calc(-264px * var(--nb))) }
            to { transform: translatex(0) }
        }
        animation: animB calc(var(--nb) * 15s) linear infinite;
    }
}

</style>
