<template>
    <section class="mh-title">
        <h1>{{ title }}</h1>
        <p v-if="subtitle" class="description">{{ subtitle }}</p>
    </section>
</template>

<script>
export default {
    name: "MhTitle",
    props: {
        title: { type: String, required: true },
        subtitle: { type: String, required: false }
    }
}
</script>

<style lang="scss">
.mh-title {
    padding: min(calc(3rem + 220 * (100vw - 320px) / 1600), 25rem) 0;
    > h1 {
        padding: 0 24px;
        max-width: 1020px;
        font-weight: 700;
        --min-size: 20;
        --max-size: 90;
        margin: min(calc(2.4rem + 220 * (100vw - 320px) / 1600), 4.8rem) auto;
    }
    > p {
        --min-size: 14;
        --max-size: 24;
        line-height: min(calc(1.4 * (100vw - 320px) / 1600), 1.4)
    }
}
</style>