<template>
    <section class="mh-content" :class="{ reverse }">
        <div class="content">
            <h2>{{ title }}</h2>
            <p v-if="description" class="description">{{ description }}</p>
        </div>
        <div class="image" :class="{ fade }">
            <slot>
                <img :src="getImage(imageFile)" />
            </slot>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MhContent',
    inject: [ 'getImage' ],
    props: {
        title: { type: String, required: true },
        description: { type: String, required: false },
        imageFile: { type: String, required: false },
        reverse: { type: Boolean },
        fade: { type: Boolean }
    }
}
</script>

<style lang="scss">
.mh-content {
    display: flex;
    flex-flow: row nowrap;
    &.reverse { flex-flow: row-reverse nowrap; }
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing) 0;

    > .content {
        margin: 0;
        width:  45%;
        text-align: left;
        --max-lh: 1.9;
        --min-lh: 1.2;
        > h2 {
            margin: max(min(calc(4.5rem + 30 * (100vw - 320px) / 1600), 1.2rem), 4.2rem) auto;
            --max-size: 45;
            --min-size: 20;
            line-height: max(min(calc(1.3 * (100vw - 320px) / 1600), 1.3), 1.1);
            margin-top: 0;
        }
    }
    > .image {
        margin: 0;
        width: 50%;
        > img {
            height: 100%;
            width: auto;
            margin-right: -50%;
        }
        &.fade > img { mask-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0)) }
    }
    &.reverse > .image > img {
        margin-right: 0;
        margin-left: -50%;
    }
}
@media (max-width: 720px) {
    .mh-content, .mh-content.reverse {
        flex-flow: column-reverse;
        > .content { width: 100% }
        > .image {
            height: min(100vw, 30vh);
            width: 100%;
            margin-bottom: 4rem;
            > img {
                height: 100%;
                margin: 0;
            }
        }
    }
    .mh-content.reverse > .content { text-align: right }
}
</style>
