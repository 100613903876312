<template>
    <section class="mh-cards">
        <h2>{{ title }}</h2>
        <p v-if="description" class="description">{{ description }}</p>
        <div class="cards">
            <mh-card
                v-for="card in cards"
                :key="card.id"
                :card="card"
            />
        </div>
    </section>
</template>

<script>
import MhCard from './MhCard.vue'

export default {
    name: 'MhCards',
    components: { MhCard },
    props: {
        cards: { type: Array, required: true },
        title: { type: String, required: true },
        description: { type: String, required: false }
    },
    data () {
        return { status: {} }
    }
}
</script>

<style lang="scss">
.mh-cards {
    > h2 {
        --max-size: 45;
        --min-size: 20;
    }
    --max-width: 172rem;
    padding: var(--spacing) 0;
    > .cards {
        margin-top: 4.8rem;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: max(min(calc(1.2rem + (60 - 12) * (100vw - 320px) / 1600), 6rem), 1.2rem)
    }
}
</style>