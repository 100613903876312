<template>
    <section class="mh-contact">
        <h2>Contactez-nous</h2>
        <!-- <form ref="form">
            <div v-for="{ name, label, type = 'text' } in fields">
                <label :for="name">{{ label }}</label>
                <input :type="type" :id="name" :name="name">
            </div>
            <div>
                <label for="msg">Message</label>
                <textarea id="msg" name="msg" style="height:200px" />
            </div>
        </form> -->
        <!-- <button @click="sendMail">Envoyer !</button> -->
        <a href="mailto:contact@mhhunting.com">contact@mhhunting.com</a>
    </section>
</template>

<script>

export default {
    name: 'MhContact',
    // data () {
    //     return {
    //         fields: [
    //             { name: 'lastname', label: 'Nom' },
    //             { name: 'firstname', label: 'Prénom' },
    //             { name: 'email', label: 'Email', type: 'email' },
    //             { name: 'phone', label: 'Numéro de téléphone', type: 'tel' }
    //         ]
    //     }
    // },
    // methods: {
    //     async sendMail () {
    //         const data = Object.fromEntries(new FormData(this.$refs.form))
    //         const response = await fetch('http://mhhunting.com/sendmail', {
    //             method: "POST",
    //             mode: "no-cors",
    //             cache: "no-cache",
    //             credentials: "same-origin",
    //             headers: { "Content-Type": "text/plain" },
    //             redirect: "follow",
    //             referrerPolicy: "no-referrer",
    //             body: JSON.stringify(data)
    //         })
    //         // return response.json()
    //     }
    // }
}
</script>

<style lang="scss">
.mh-contact {
    text-align: center; // align left
    --max-width: 62rem;
    padding: var(--spacing) 0;
    margin-bottom: 25vh !important; // remove me
    > h2 {
        --min-size: 20;
        --max-size: 45;
    }
    // form {
    //     width: 100%;
    //     display: flex;
    //     flex-flow: row wrap;
    //     gap: 2.1rem 4.2rem;
    //     > div {
    //         &:first-child, &:nth-child(2) {
    //             flex: 1 0 calc(50% - 2.1rem);
    //             min-width: 28rem;
    //         }
    //         width: 100%;
    //         > * { display: block }
    //         > label {
    //             font-weight: 400;
    //             color: #878EA0;
    //             line-height: 1.9;
    //             font-size: 1.8rem;
    //         }
    //         > input, > textarea {
    //             background-color: #06102F;
    //             border-radius: 5px;
    //             font-size: 18px;
    //             border: none;
    //             color: white;
    //             height: 4rem;
    //             width: 100%;
    //             padding: 1rem;
    //             &:focus {
    //                 background-color: #2E3C68;
    //                 outline: none;
    //             }
    //         }
    //         > textarea {
    //             resize: vertical;
    //             min-height: 6rem;
    //         }
    //     }
    // }
    > button, > a {
        display: inline-block;
        background-color: #2E3C68;
        border: none;
        border-radius: 1000px;
        margin-top: 4rem;
        padding:
            max(min(calc(1.2rem + 16 * (100vw - 320px) / 1600), 1.6rem), 1.2rem)
            max(min(calc(2rem + 36 * (100vw - 320px) / 1600), 2rem), 3.6rem);
        line-height: 1;
        --max-size: 24;
        --min-size: 20;
        color: white;
        text-decoration: none;
        transition: background-color 0.1s ease-out;
        &:hover { background-color: #06102F }
        cursor: pointer;
    }
}
</style>