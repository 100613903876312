<template>
    <div class="mh-card">
        <div v-if="card.opt2" class="switch">

            <span>{{ card.opt1.duration }} mois</span>
            <mh-switch v-model="status" />
            <span>{{ card.opt2.duration }} mois</span>
        </div>
        <div v-else>{{ card.opt1.duration }} mois</div>
        <h3 class="name">{{ card.name }}</h3>
        <h3 class="price">
            <transition name="change" mode="out-in"><span :key="option.price">{{ option.price }}</span></transition> / mois</h3>
        <div class="search">{{ card.nSearch }} recherche{{ card.nSearch > 1 ? 's' : '' }} a la fois</div>
    </div>
</template>

<script>
import MhSwitch from './MhSwitch.vue'

export default {
    name: 'MhCard',
    inject: [ 'scrollTo' ],
    components: { MhSwitch },
    props: {
        card: { type: Object, required: true }
    },
    data () {
        return { status: false }
    },
    computed: {
        option () {
            if (this.card.opt2 && this.status) {
                return this.card.opt2
            }
            return this.card.opt1
        }
    }
}
</script>

<style lang="scss">
.mh-card {
    flex: 0 1 38.5rem;
    max-width: 38.5rem;
    margin: 0;
    padding: max(min(calc(2.4rem + 14 * (100vw - 320px) / 1600), 3.8rem), 2.4rem);
    border-radius: 25px;
    background-color: #06102F;
    > .switch {
        display: flex;
        flex-flow: row nowrap;
        gap: 3.4rem;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        color: #878EA0;
    }
    > .name, > .price, .search { text-align: left }
    > .name {
        font-weight: 400;
        --max-size: 36;
        --min-size: 20;
    }
    > .price {
        color: #0A3FF5;
        font-weight: 700;
        --max-size: 45;
        --min-size: 16;
        overflow: hidden;
        > span { display: inline-block }
        &::after {
            content: 'HT';
            font-size: 1.5rem;
            margin-left: max(min(calc(1.2rem + 12 * (100vw - 320px) / 1600), 2.4rem), 1.2rem);
            font-weight: normal;
        }
    }
    > .search {
        border-top: 1px solid #2E3C68;
        padding-top: max(min(calc(1.2rem + 26 * (100vw - 320px) / 1600), 3.4rem), 1.2rem);
        color: #878EA0;
        font-size: 1.8rem;
    }
    .change-enter-active,
    .change-leave-active {
        transition:
            opacity 0.25s ease-out,
            margin-left 0.25s ease-out;
    }
    .change-enter, .change-leave-to {
        margin-left: -25%;
        opacity: 0;
    }
}
</style>